class SmoothScroll {
	constructor() {
		this.init();
	}

	init() {
		this._anchorLinks = Array.from(document.querySelectorAll('a[href^="#"]'));

		this._anchorLinks.forEach(anchor => {
			anchor.addEventListener('click', function(e) {
				e.preventDefault();

				const targetEl = document.querySelector(this.getAttribute('href'));

				if (targetEl) {
					targetEl.scrollIntoView({
						behavior: 'smooth',
					});
				}
			});
		});
	}
}

export default new SmoothScroll();
